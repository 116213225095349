import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createI18n } from "vue-i18n/index";
import messages from "./i18n/messages.json";
import Main from "./views/Main.vue";
import Landing from "./views/Landing.vue";
import App from "./App.vue";
const { worker } = require("./mocks/browser");

if (process.env.NODE_ENV === "development") {
  worker.start({ onUnhandledRequest: "bypass" });
  worker.printHandlers();
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Landing },
    { path: "/:slug/:userId", component: Main },
    { path: "/:slug", component: Main }
  ]
});

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop)
});

const i18n = createI18n({
  locale: params.lang || "et",
  fallbackLocale: "et",
  messages
});
export const t = i18n.global.t;
document.title = t("windowTitle");

createApp(App)
  .use(router)
  .use(i18n)
  .mount("#app");
