<template>
  <div class="content">
    <header class="vff-header">
      <div class="f-container">
        <img class="logo" src="../assets/images/logo.png" />
        <div>{{ $t("logoSubtitle") }}</div>
      </div>
    </header>
    <div class="vff">
      <div class="f-section-wrap">
        <span class="questionnaire-demo fh2" v-html="$t('landingText')"></span>
      </div>
    </div>
  </div>
</template>

<style lang="css">
@import "../../FlowForm/assets/css/themes/theme-minimal.css";
.logo {
  max-height: 75px;
}
.questionnaire-demo {
  text-align: center;
}
span.questionnaire-demo.fh2 a {
  color: #007eff;
  text-decoration: underline;
}
span.questionnaire-demo.fh2 a:hover {
  color: #007eff;
  text-decoration: underline;
}
</style>
