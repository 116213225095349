import { t } from "../main";

export const getErrorMessage = error => {
  if (error?.response?.data) {
    const { message } = error.response.data;
    return (
      {
        "User already exists": t("error.alreadyAnswered"),
        "Survey is expired": t("error.expired"),
        "Survey is not started": t("error.notStarted"),
        "Not Found": t("error.notFound")
      }[message] || t("error.unknown")
    );
  }
  return t("error.unknown");
};

export const getPostErrorMessage = error => {
  if (error?.response?.data) {
    const { message } = error.response.data;
    return (
      {
        "User already exists": t("error.alreadyAnswered"),
        "Survey is expired": t("error.expired"),
        "Survey is not started": t("error.notStarted"),
        "Not Found": t("error.notFound")
      }[message] || false
    );
  }
  return false;
};
