import { rest } from "msw";
import survey from "./survey2.json";

export default [
  rest.get("https://api.kysitlus.ee/surveys/:slug", (_req, res, ctx) => {
    return res(ctx.json(survey));
  }),
  rest.get(
    "https://api.kysitlus.ee/surveys/:slug/:userId",
    (_req, res, ctx) => {
      return res(ctx.json(survey));
    }
  ),
  rest.post("https://api.kysitlus.ee/answers/", (_req, res, ctx) => {
    return res(ctx.json({ message: "ok" }));
  })
];
