import axios from "axios";

export const getQuestionnaire = async (slug, userId) => {
  const url = userId
    ? `https://api.kysitlus.ee/surveys/${slug}/${userId}`
    : `https://api.kysitlus.ee/surveys/${slug}`;
  const response = await axios.get(url);
  return response.data;
};

export const sendResponses = async data => {
  const response = await axios.post(`https://api.kysitlus.ee/answers`, data);
  return response.data;
};
