<template>
  <div class="content-wrapper" v-if="getSurveyError">
    <div class="content">
      <header class="vff-header">
        <div class="f-container">
          <img class="logo" src="../assets/images/logo.png" />
          <div>{{ $t("logoSubtitle") }}</div>
        </div>
      </header>
      <div class="vff">
        <div class="f-section-wrap">
          <span class="questionnaire-demo fh2">
            {{ getSurveyError }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="survey" class="content">
    <header class="vff-header">
      <div class="f-container">
        <img class="logo" v-bind:src="logo" />
        <div>{{ title }}</div>
      </div>
    </header>

    <flow-form
      ref="flowform"
      v-on:complete="onComplete"
      v-on:submit="onSubmit"
      v-bind:progressbar="false"
      v-bind:questions="questions"
      v-bind:language="language"
      v-bind:standalone="true"
    >
      <template v-slot:complete>
        <div class="f-section-wrap" v-if="submitted">
          <p>
            <span class="fh2">{{ $t("success") }}</span>
            <span class="f-section-text">
              {{ $t("thankYou") }}
            </span>
          </p>
        </div>
        <div class="f-section-wrap" v-else-if="submitting">
          <p>
            <span class="fh2">{{ $t("sending") }}</span>
          </p>
        </div>
        <div class="f-section-wrap" v-else-if="!submitted">
          <p>
            <span class="fh2">{{
              postErrorMessage || genericPostSurveyError
                ? $t("failure")
                : $t("ready")
            }}</span>
          </p>
        </div>
      </template>
      <template v-slot:completeButton>
        <div class="f-submit">
          <p v-if="submitting"></p>
          <p v-else-if="postErrorMessage">
            {{ postErrorMessage }}
          </p>
          <div v-else-if="!submitted">
            <p v-if="genericPostSurveyError">
              {{ $t("tryAgain") }}
            </p>
            <button
              class="o-btn-action"
              ref="button"
              type="submit"
              href="#"
              v-on:click.prevent="onSendData()"
              aria-label="Press to submit"
            >
              <span>{{
                genericPostSurveyError ? $t("tryAgain") : $t("submitText")
              }}</span>
            </button>
            <a
              class="f-enter-desc"
              href="#"
              v-on:click.prevent="onSendData()"
              v-html="language.formatString(language.pressEnter)"
            >
            </a>
          </div>
        </div>
      </template>
    </flow-form>
  </div>
</template>

<script>
import { FlowForm } from "../../FlowForm/main";
import QuestionModel, {
  QuestionType,
  ChoiceOption
} from "../../FlowForm/models/QuestionModel";
import LanguageModel from "../../FlowForm/models/LanguageModel";
import { getQuestionnaire, sendResponses } from "../services/http";
import { getErrorMessage, getPostErrorMessage } from "../utils/getErrorMessage";
import { t } from "../main";

export default {
  name: "Main",
  components: {
    FlowForm
  },
  created() {
    this.fetchData();
  },

  data() {
    return {
      logo: null,
      title: null,
      survey: false,
      loading: false,
      post: null,
      getSurveyError: null,
      genericPostSurveyError: false,
      postErrorMessage: null,
      submitted: false,
      submitting: false,
      completed: false,
      language: new LanguageModel({
        enterKey: "Enter",
        shiftKey: "Shift",
        ok: t("ok"),
        continue: t("start"),
        skip: t("skip"),
        pressEnter: t("pressEnter"),
        placeholder: t("placeholder"),
        submitText: t("submitText"),
        invalidPrompt: t("invalidPrompt"),
        prev: t("prev"),
        next: t("next")
      }),
      questions: []
    };
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyListener);
  },

  beforeUnmount() {
    document.removeEventListener("keyup", this.onKeyListener);
  },

  methods: {
    async fetchData() {
      this.loading = true;
      const slug = this.$route.params.slug;
      const userId = this.$route.params.userId;

      getQuestionnaire(slug, userId)
        .then(data => {
          this.loading = false;
          this.logo = data.logo || "https://www.coop.ee/themes/public/logo.svg";
          this.title = data.title;
          this.questions = this.generateQuestions(data);
          this.slug = data.slug;
          this.survey = true;
        })
        .catch(e => {
          this.loading = false;
          this.getSurveyError = getErrorMessage(e);
        });
    },

    generateQuestions(json) {
      return json.questions.map(q => {
        let jumpFunction = null;
        if (q.type === "LongText" && q.jump) {
          jumpFunction = () => q.jump;
        }
        return new QuestionModel({
          id: q.id,
          title: q.title,
          tagline: q.tagline,
          helpTextShow: q.helpTextShow,
          multiple: q.multiple,
          content: q.content,
          type: QuestionType[q.type],
          required: q.required,
          subtitle: q.subtitle,
          placeholder: q.placeholder,
          options: q.options?.map(
            o =>
              new ChoiceOption({
                label: o.label,
                value: o.value
              })
          ),
          jump: jumpFunction || q.jump
        });
      });
    },

    onKeyListener($event) {
      if (this.submitting) return;
      if ($event.key === "Enter" && this.completed && !this.submitted) {
        this.onSendData();
      }
    },

    onComplete(completed) {
      this.completed = completed;
    },

    onSubmit() {
      this.onSendData();
    },

    async onSendData() {
      const data = this.getData();
      this.submitting = true;
      this.genericPostSurveyError = false;
      await sendResponses(data)
        .then(() => {
          this.submitted = true;
          this.submitting = false;
          this.genericPostSurveyError = false;
          this.$refs.flowform.submitted = true;
        })
        .catch(e => {
          this.submitting = false;
          const message = getPostErrorMessage(e);
          if (message) {
            this.postErrorMessage = message;
          } else {
            this.genericPostSurveyError = true;
          }
        });
    },

    getData() {
      const data = {
        identifier: this.$route.params.userId,
        userId: this.$route.params.userId,
        language: this.$route.query.lang || "et",
        slug: this.slug,
        responses: []
      };

      this.questions.forEach(question => {
        if (question.type === "FlowFormSectionBreakType") {
          return;
        }
        data.responses.push({
          identifier: question.id,
          value: question.answer
        });
      });

      return data;
    }
  }
};
</script>

<style lang="css">
@import "../../FlowForm/assets/css/themes/theme-minimal.css";
.vff .o-btn-action {
  text-transform: uppercase;
}
.vff ul.f-radios li {
  cursor: pointer;
}

.vff input[type="date"],
.vff input[type="email"],
.vff input[type="number"],
.vff input[type="password"],
.vff input[type="tel"],
.vff input[type="text"],
.vff input[type="url"],
.vff textarea {
  font-weight: normal;
}
.vff-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.logo {
  max-height: 75px;
}
</style>
